
let editor
let inputField
document.addEventListener('DOMContentLoaded', () => {
    document.getElementById("parse").addEventListener("click", sendCodeToServer);
    document.getElementById("run").addEventListener("click", sendParamsToServer);
    
    editor = CodeMirror(document.getElementById('inputcode'), {
        value: 'While x1 > 0 Do\n    x2 = x2 + 1;\n    x1 = x1 - 1\nEnd;\nx0 = x2 + 0',
        mode: "loopwhile",
        theme: 'solarized',
        lineNumbers: true,
    });

    inputField = CodeMirror(document.getElementById('params'), {
        value: "2, 15",
        mode: "loopwhile",
        theme: 'solarized',
        lineNumbers: true,
    });
})


function sendCodeToServer() {
    const code = editor.getValue()
    sendToServer(code)
}

function sendParamsToServer() {
    const code = editor.getValue()
    const params = inputField.getValue()
    sendToServer(code, params)
}

function sendToServer(body, params) {
    const url = `${window.location.href}api/code${params ? `?args=${params}` : ''}`
    fetch(url, {
        body,
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain; charset=utf-8'
        }
    })
        .then(r => r.text())
        .then(handleResult)
}

let codeMirrorRead;
let codeMirrorParsed;

function handleResult(result) {
    const json = JSON.parse(result)
    destroyCodeMirror(codeMirrorRead)
    destroyCodeMirror(codeMirrorParsed)
    
    document.querySelectorAll('code').forEach(ele => ele.innerText = '')
    if (json.parseError) {
        document.querySelector('#parseError').innerText = "ERROR IN CODE:\n".concat(json.parseError)
    }
    if (json.paramError) {
        document.querySelector('#paramError').innerText = "ERROR IN PARAMS:\n".concat(json.paramError)
    }
    if (json.readCode) {
        codeMirrorRead = initReadonlyCodeMirror(document.getElementById('readCode'), "\nREAD CODE:\n\n".concat(json.readCode), 'loopwhile')
    }
    if (json.parsedCode) {
        codeMirrorParsed = initReadonlyCodeMirror(document.getElementById('parsedCode'), "PARSED CODE:\n".concat(json.parsedCode), 'haskell')
    }
    if (json.parsedInputs) {
        codeMirrorInputsParsed = initReadonlyCodeMirror(document.getElementById('parsedInputs'), "PARSED PARAMETERS:\n".concat(json.parsedInputs),'haskell')
    }
    if (json.result) {
        codeMirrorResult = initReadonlyCodeMirror(document.getElementById('result'), "RESULT:\n".concat(json.result),'haskell')
    }
}

function destroyCodeMirror(cm) {
    if (cm && cm.toTextArea) {
        cm.toTextArea()
    }
}

function initReadonlyCodeMirror(ele, code, mode) {
    let cm = CodeMirror(ele, {
        value: code,
        mode: mode,
        theme: 'default',
        lineWrapping: true,
        readOnly: true
    });
    /* https://github.com/codemirror/CodeMirror/issues/4762 */
    cm.on('copy', (cm, e) => {
        // ignore copy by codemirror.  and will copy by browser
        e.codemirrorIgnore = true;
    });
    return cm;
}